import { useMemo } from "react";
import { useWeb3 } from "./Provider";

export const useApi = (Api: any, isBnb?: boolean, disabled?: boolean) => {
  const { provider } = useWeb3();
  return useMemo(() => {
    if (!provider || disabled) {
      return null;
    }
    return new Api(provider, isBnb);
  }, [provider]);
};
