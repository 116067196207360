import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Spin } from "antd";

const Layout: React.FC = () => {
  return (
    <Suspense
      fallback={
        <div className="flex h-screen w-full items-center justify-center">
          <Spin spinning />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  );
};

export default Layout;
