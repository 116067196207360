/**
 * connect
 * @param connectInfo
 */
const handleConnect = (connectInfo: any) => {
  console.log("connect", connectInfo);
};

/**
 * networkVersion
 */
const handleNetworkChanged = (networkVersion: any) => {
  console.log("networkVersion", networkVersion);
  // window.location.reload();
};

/**
 * registerListenEvent
 */
export const registerListenEvent = (
  provider: any,
  handleAccountsChanged: (accounts: any) => void,
  handleChainChanged: (chainId: string) => void,
  handleDisconnect: () => void
) => {
  if (!provider) return;
  provider.on("connect", handleConnect);
  provider.on("disconnect", handleDisconnect);
  provider.on("accountsChanged", handleAccountsChanged);
  provider.on("chainChanged", handleChainChanged);
  provider.on("networkChanged", handleNetworkChanged);
};

/**
 * removeListener
 */
export const removeListenEvent = (
  provider: any,
  handleAccountsChanged: (accounts: any) => void,
  handleChainChanged: (chainId: string) => void,
  handleDisconnect: () => void
) => {
  if (!provider) return;
  provider.removeListener("connect", handleConnect);
  provider.removeListener("disconnect", handleDisconnect);
  provider.removeListener("accountsChanged", handleAccountsChanged);
  provider.removeListener("chainChanged", handleChainChanged);
  provider.removeListener("networkChanged", handleNetworkChanged);
};
