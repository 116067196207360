import React, {
  useState,
  useCallback,
  createContext,
  useContext,
  useEffect,
  PropsWithChildren,
} from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import TradeStatusModal from "./TradeStatusModal";
import { registerListenEvent, removeListenEvent } from "./listener";
import { Web3Type, Web3StateType } from "./type";

export const Wb3Context = createContext<Web3Type | undefined>(undefined);

export const Wb3Provider: React.FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const [web3State, setWeb3State] = useState<Web3StateType>(() => {
    const ethereum = window.ethereum ?? null;
    // only support MetaMask
    const isInstalled = ethereum && ethereum.isMetaMask;
    return {
      provider: isInstalled ? ethereum : null,
      wallet: {
        address: "",
      },
      isInstalled,
      tradeStatus: 0,
    };
  });

  const setAddress = (address: string) => {
    setWeb3State((prevState) => {
      const { wallet, ...reset } = prevState;
      return {
        ...reset,
        wallet: {
          ...wallet,
          address,
        },
      };
    });
  };

  const connectWallet = useCallback(async () => {
    if (web3State.wallet.address) {
      return;
    }
    try {
      const accounts = await web3State.provider?.request({
        method: "eth_requestAccounts",
      });
      const address = accounts?.[0] ?? "";
      setAddress(address);
    } catch (error) {
      console.log(error);
    }
  }, [web3State.wallet]);

  const setTradeStatus = (tradeStatus: Web3StateType["tradeStatus"]) => {
    setWeb3State((prevState) => ({
      ...prevState,
      tradeStatus,
    }));
  };

  useEffect(() => {
    const handleAccountsChanged = (accounts: any) => {
      setAddress(accounts?.[0] ?? "");
    };

    const handleChainChanged = (chainId: string) => {
      window.location.reload();
    };

    const logout = () => {
      setAddress("");
    };

    registerListenEvent(
      web3State?.provider,
      handleAccountsChanged,
      handleChainChanged,
      logout
    );

    return () => {
      removeListenEvent(
        web3State?.provider,
        handleAccountsChanged,
        handleChainChanged,
        logout
      );
    };
  }, []);

  useEffect(() => {
    // if (pathname !== "/") {
    //   connectWallet();
    // }
    connectWallet();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [pathname]);

  return (
    <Wb3Context.Provider
      value={{ ...web3State, connectWallet, setTradeStatus }}
    >
      {children}
      <TradeStatusModal />
    </Wb3Context.Provider>
  );
};

export const useWeb3 = () => {
  const wb3Context = useContext(Wb3Context);

  if (wb3Context === undefined) {
    throw new Error("Wallet context is undefined");
  }

  return wb3Context;
};
