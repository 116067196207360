import {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useCallback,
} from "react";
import enConfig from "./config/en-US";
import cnConfig from "./config/zh-CN";

const LANGUAGE_KEY = "_LANGUAGE_KEY_";

export enum Language {
  en = "en-US",
  cn = "zh-CN",
}

const languageMap: Record<Language, Record<string, string>> = {
  [Language.en]: enConfig,
  [Language.cn]: cnConfig,
};

type TranslateFunction = (
  key: string,
  replaceData?: Record<string, string | number>
) => string;

type ProviderState = {
  locale: Language;
};

type ContextApi = ProviderState & {
  setLocale: (language: Language) => void;
  t: TranslateFunction;
};

export const LANGUAGE_OPTIONS = [
  {
    label: "English",
    key: Language.en,
  },
  {
    label: "简体中文",
    key: Language.cn,
  },
];

const initialState: ProviderState = {
  locale: Language.en,
};

export const LanguageContext = createContext<ContextApi | undefined>(undefined);

export const useTranslation = () => {
  const languageContext = useContext(LanguageContext);

  if (languageContext === undefined) {
    throw new Error("Language context is undefined");
  }

  return languageContext;
};

export const LanguageProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<ProviderState>(() => {
    const lsLocale = window.localStorage.getItem(LANGUAGE_KEY) || Language.en;

    return {
      ...initialState,
      locale: lsLocale as Language,
    };
  });

  const setLocale = (locale: Language) => {
    setState((prevState) => ({
      ...prevState,
      locale,
    }));
    window.localStorage.setItem(LANGUAGE_KEY, locale || Language.en);
  };

  const t: TranslateFunction = useCallback(
    (text, replaceData) => {
      return languageMap[state.locale]?.[text] ?? text;
    },
    [state.locale]
  );

  return (
    <LanguageContext.Provider value={{ ...state, setLocale, t }}>
      {children}
    </LanguageContext.Provider>
  );
};
