export default {
    "Connect": "連接錢包",
    "Home": "首頁",
    "Burn": "燃燒",
    "Approve": "授權",
    "AVEN Burn": "AVEN 燃燒",
    "USDT Burn": "USDT 燃燒",
    "Staking": "挖礦",
    "Invitation Rewards": "邀請规则",
    "AVEN is a diversified application aggregation platform": "Aven由安貝基金與全球領先的區塊鏈技術團隊共同創立",
    "Introducing Anen": "ANEN 介紹",
    "Multi-Function Platform": "多功能平台",
    "AVEN is a diversified application aggregation platform created by Anbei Fund and the world-renowned top blockchain technology team. Aven is similar to a platform for querying and viewing contracts, but its functions are richer and more comprehensive. It can not only provide contract query and audit services for blockchain industry enthusiasts, but also help with publicity, token issuance, trading and independent NFT transactions. In addition, Aven also supports the development of inscriptions and runes, and provides group building and chat services. Its automatic data clearing function ensures absolute security. With these functions, Aven has become an indispensable multi-functional platform in the blockchain field.": "AVEN是安貝基金與全球知名頂尖區塊鏈技術團隊共同打造的多元化應用聚合平台。 Aven類似於一個查詢查看合約的平台，但功能更豐富、更全面。它不僅可以為區塊鏈行業愛好者提供合約查詢和審計服務，還可以幫助宣傳、代幣發行、交易和獨立的NFT交易。此外，Aven也支援銘文和符文的開發，並提供建群和聊天服務。其自動資料清除功能確保絕對安全。憑藉這些功能，Aven已成為區塊鏈領域不可或缺的多功能平台。",
    "VALUE": "價值",
    "Security": "安全",
    "Contract query and audit": "合約查詢與審核",
    "Aven provides contract query and audit services for blockchain industry enthusiasts to ensure the security and transparency of contracts.": "Aven為區塊鏈產業愛好者提供合約查詢和審計服務，保證合約的安全性和透明度",
    "Token": "代幣",
    "Token issuance and trading": "代幣發行和交易",
    "Supports the issuance and trading of tokens, and provides a convenient trading platform.": "支援代幣的發行和交易，提供方便的交易平台。",
    "nft": "nft",
    "Independent NFT trading": "獨立NFT交易",
    "Provides NFT trading, inscription and rune development services to expand blockchain application scenarios.": "提供NFT交易、銘文、符文開發服務，拓展區塊鏈應用場景",
    "Chat": "聊天",
    "Group creation service": "建群服務",
    "Aven's group creation and chat functions are very powerful, with automatic data clearing function to ensure absolute security.": "Aven的建群和聊天功能非常強大，具有自動資料清除功能，保證絕對安全",
    "economic model": "經濟模型",
    "Total AVEN tokens: 100 million": "AVEN 代幣總量：1 億",
    "Transaction slippage": "交易滑點",
    "token burning dividends": "代幣燃燒分紅",
    "USDT burning dividends": "燃燒分紅",
    "foundation dividends": "基金會分紅",
    "Token burning (2000):": "代幣燃燒（2000）：",
    "USDT burning dividend (minimum 100U)": "USDT銷毀分紅（最低100U）",
    "Pool mechanism:": "礦池燃燒機制：",
    "0.5% deflation per hour (burning)": "每小時 0.5% 通貨緊縮（燃燒）",
    "12% deflation per day (burning)": "每天12%通貨緊縮（燃燒）",
    "Minimum": "最低限度",
    "output": "產出",
    "Settlement": "結算",
    "Gold standard settlement": "金本位結算",
    "Daily settlement (settlement value 1U of AVEN)": "每日結算 (AVEN的結算值1U)",
    "example: today the AVEN price is 0.0001/coin, and the mining machine with 100u of computing power has 1u of computing power per day, 1u/0.0001=1000 AVEN, and tomorrow the price is 0.0002/coin, which means 1u/0.0002=500 AVEN. In short, it is a gold-based currency settlement.": "舉例: 今天AVEN價格0.0001/幣，100u算力的礦機每天有1u算力，1u/0.0001=1000 AVEN，明天價格0.0002/幣，即1u/0.0002= 500 AVEN。簡而言之，就是以金本位的幣量結算。",
    "Recommendation": "推薦",
    "Award": "獎",
    "It is recommended that one person with more than 100u buy the first generation (direct push, three outputs, no overflow, no storage, no acceleration), and two people with more than 100u buy the second generation, and so on.": "推薦獎：推薦1人100u以上拿1代直推收益10%(直推，三倍出局，不溢出，不能存、加速），推薦2人100u以上拿2代收益5%，推薦3人拿3代收益3%，推薦4人拿4代收益2%，推薦5人拿5代收益2000，推薦6人拿6代收益2000，以此類推.",
    "Binding Address": "綁定地址",
    "Binding": "綁定",
    "Users who burn USDT will receive a 0.9% GAS revenue share, which can be withdrawn at any time. 50% of the destroyed USDT will go into the LP base pool, and the rest will be purchased in AVEN and destroyed.": "用戶燃燒USDT將獲得 0.9% 的 GAS 收入份額，可隨時提取。銷毀的USDT 50%進入LP基礎池，其餘購買AVEN銷毀。",
    "Token holders can voluntarily destroy their tokens to obtain USDT and receive a 0.9% share of transactions. After destruction, the referrer will receive a 10% bonus. USDT earnings can be withdrawn at any time, but once the earnings reach three times the destruction amount, the sharing will stop.": "代幣持有者可以自願銷毀其代幣以獲得USDT，並獲得 0.9% 的交易份額。銷毀後，推薦人將獲得 10% 的獎勵。USDT收益可以隨時提取，但一旦收益達到銷毀金額的三倍，共享就會停止。",
    "USDT Total Burn": "USDT 燃燒總量",
    "AVEN Total Burn": "AVEN 燃燒總量",
    "Total Rewards": "總獎勵量",
    "Total Receive": "總領取量",
    "My Details": "我的燃燒",
    "Maximum burn": "最大燃燒数量",
    "Balance": "余额",
    "Burn amount": "燃燒数量",
    "Your burn": "你的燃燒",
    "Your proportion": "你的比例",
    "Maximum reward": "最大奖励数量",
    "Reward": "奖励数量",
    "Available rewards": "有效奖励",
    "Receive": "領取獎勵",
    "Minimum burn": "最小燃燒数量",
    "Receive record": "領取記錄",
    "Date": "時間",
    "Amount": "數量",
    "Type": "類型",
    "State": "狀態",
    "Burn rewards": "燃燒獎勵",
    "Success": "已領取",
    "BTBT Total synthesis": "BTB合成總量",
    "ANEN Total synthesis": "ANEN合成總量",
    "Referral Rewards": "直推獎勵",
    "Team Rewards": "團隊獎勵",
    "Hashrate": "算力",
    "Mining": "挖礦",
    "My Mining": "我的挖礦",
    "Invest Token": "投入Token",
    "Bind inviter address": "綁定邀請人地址",
    "Invest BTBT": "投入BTBT",
    "Invest USDT": "投入USDT",
    "Invest AVEN": "投入AVEN",
    "min investment:": "最少投入:",
    "Start mining": "開始挖礦",
    "Bind": "綁定",
    "Destruction BTBT": "銷毀 BTBT",
    "Destruction AVEN": "銷毀 AVEN",
    "Destruction value": "銷毀價值",
    "Current level": "當前等級",
    "Cumulative income": "累積收益",
    "Destroy Hash": "銷毀Hash",
    "Destruction time": "銷毀時間",
    "Mining income": "挖礦收益",
    "50% BTBT + 50% AVEN combination mining machine (100U mining machine, 50U BTBT + 50U AVEN), output currency is AVEN, gold standard settlement, minimum 100U, daily settlement (settlement value 1U AVEN), 100u mining machine daily income 2000, settlement is based on the currency price of the day.": "50%BTBT+50%AVEN組合成礦機（100U的礦機，50U的BTBT+50U的AVEN），產出幣種為AVEN，金本位方式結算，最低100U，日結（結算價值1U的AVEN），100u組礦每天收益2000，結算是以當天幣價數量結算。",
    "Recommendation Award: Recommend 1 person with more than 100u to get 10% of the first generation direct promotion income (direct promotion, three times out, no overflow, no storage, no acceleration), recommend 2 people with more than 100u to get 5% of the second generation income, recommend 3 people to get 3% of the third generation income, recommend 4 people to get 2% of the fourth generation income, recommend 5 people to get 2000 of the fifth generation income, recommend 6 people to get 2000 of the sixth generation income, and so on.": "推薦獎：推薦1人100u以上拿1代直推收益10%(直推，三倍出局，不溢出，不能存、加速），推薦2人100u以上拿2代收益5%，推薦3人拿3代收益3%，推薦4人拿4代收益2%，推薦5人拿5代收益2000，推薦6人拿6代收益2000，以此類推.",
    "Recommended number": "直推人数",
    "Team size": "團隊人数",
    "Invitation List": "邀请列表",
    "Settlement time": "結算時間",
    "Level": "等級",
    "Income rewards": "直推收益",
    "Recommended address": "直推地址",
    "Please bind the address first": "請先綁定地址"
}