import { lazy } from "react";
import Layout from "../layout";
import { ReactComponent as Footer1 } from "../assets/footer_1.svg";
import { ReactComponent as Footer2 } from "../assets/footer_2.svg";
import { ReactComponent as Footer3 } from "../assets/footer_3.svg";
import { ReactComponent as Footer4 } from "../assets/footer_4.svg";
import { ReactComponent as Footer5 } from "../assets/footer_5.svg";

const Home = lazy(() => import("../pages/home/index"));
const TokenBurn = lazy(() => import("../pages/tokenBurn"));
const UsdtBurn = lazy(() => import("../pages/usdtBurn"));
const Staking = lazy(() => import("../pages/staking"));
const Invitation = lazy(() => import("../pages/invitation"));

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
        title: "Home",
        icon: <Footer1 />,
      },
      {
        path: "/token-burn",
        element: <TokenBurn />,
        title: "AVEN Burn",
        icon: <Footer2 />,
      },
      {
        path: "/usdt-burn",
        element: <UsdtBurn />,
        title: "USDT Burn",
        icon: <Footer3 />,
      },
      {
        path: "/staking",
        element: <Staking />,
        title: "Staking",
        icon: <Footer4 />,
      },
      {
        path: "/invitation",
        element: <Invitation />,
        title: "Invitation Rewards",
        icon: <Footer5 />,
      },
      {
        path: "*",
        element: <Home />,
        title: "",
      },
    ],
  },
];

export const hiddenRoutes: string[] = [];

export default routes;
