import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { ConfigProvider, theme } from "antd";
import { Wb3Provider } from "./packages/web3";
import { LanguageProvider } from "./language";
import { ReactComponent as NodataIcon } from "./assets/no_data.svg";
import "./index.css";
import App from "./App";

const customizeRenderEmpty = () => (
  <div className="flex flex-col items-center justify-center gap-y-2 py-10">
    <NodataIcon className="w-[70px]" />
    <p className="text-sm text-[#BACEB6]">No Data</p>
  </div>
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <HashRouter>
    <ConfigProvider
      renderEmpty={customizeRenderEmpty}
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorBgContainer: "#1A1B1D",
          colorText: "#fff",
          colorTextPlaceholder: "#4D4D4D",
          colorPrimaryActive: "#FFBE00",
          colorPrimaryHover: "#FFBE00",
          colorBorder: "#FFBE00",
        },
        components: {
          Table: {
            headerBg: "#1A1B1D",
            footerBg: "#1A1B1D",
            borderColor: "#303030",
            headerSplitColor: "transparent",
            headerColor: "#C9C8C5",
            rowHoverBg: "rgba(0, 0, 0, .15)",
            cellPaddingInline: isMobile ? 15 : 30,
          },
          Input: {
            activeBorderColor: "#FFBE00",
            hoverBorderColor: "#FFBE00",
            hoverBg: "#1A1B1D",
            activeBg: "#1A1B1D",
          },
          Button: {
            defaultBg: "#FFBE00",
            defaultColor: "#000",
            defaultHoverColor: "#000",
          },
          Pagination: {
            itemActiveBg: "#FFBE00",
            itemBg: "#FFBE00",
          },
          Message: {
            contentBg: "#1A1B1D",
          },
          Tabs: {
            itemSelectedColor: "#FFBE00",
            inkBarColor: "#FFBE00",
          },
          Dropdown: {
            colorPrimary: "#FFBE00",
            controlItemBgActiveHover: "rgba(228, 161, 1, .2)",
            controlItemBgActive: "rgba(228, 161, 1, .2)",
          },
          Spin: {
            colorPrimary: "#FFBE00",
          },
        },
      }}
    >
      <LanguageProvider>
        <Wb3Provider>
          <App />
        </Wb3Provider>
      </LanguageProvider>
    </ConfigProvider>
  </HashRouter>
);
