import React, { useState, useMemo, useEffect } from "react";
import { Modal } from "antd";
import clsx from "clsx";
import { useWeb3 } from "./Provider";
import {
  LoadingIcon,
  SubmitIcon,
  SuccessIcon,
  ErrorIcon,
} from "../../assets/trade";

const TradeStatusModal: React.FC = () => {
  const { tradeStatus } = useWeb3();
  const [open, setOpen] = useState<boolean>(false);

  const modalConfig = useMemo(() => {
    switch (tradeStatus) {
      case 1:
        return {
          classes: "animate-spin",
          title: "Waiting for confirmation",
          info: "Confirm this transaction in your wallet.",
          icon: (
            <LoadingIcon className="loading-icon h-[80px] w-[80px] animate-spinFast" />
          ),
          btnText: "",
        };
      case 2:
        return {
          classes: "",
          title: "Transaction submitted",
          info: "Transaction processing.",
          icon: <SubmitIcon className="h-[80px] w-[80px]" />,
          btnText: "Close",
        };
      case 3:
        return {
          classes: "",
          title: "Success",
          info: "Processed successfully.",
          icon: <SuccessIcon className="h-[80px] w-[80px]" />,
          btnText: "Close",
        };
      case 4:
        return {
          classes: "",
          title: "Error",
          info: "Transaction rejected.",
          icon: <ErrorIcon className="h-[80px] w-[80px]" />,
          btnText: "Close",
        };
      case 0:
      default:
        return {
          classes: "",
          title: "",
          info: "",
          icon: "",
          btnText: "",
        };
    }
  }, [tradeStatus]);

  useEffect(() => {
    setOpen(tradeStatus > 0);
  }, [tradeStatus]);

  if (tradeStatus === 0) return null;

  return (
    <Modal
      open={open}
      title={<span className="text-lg">{modalConfig.title}</span>}
      width={350}
      footer={null}
      maskClosable={false}
      onCancel={() => setOpen(false)}
    >
      <div
        className={clsx(
          "relative mt-10 flex items-center justify-center",
          tradeStatus <= 1 ? "mb-10" : "mb-6"
        )}
      >
        {modalConfig.icon}
      </div>
      <p className="text-center text-white">{modalConfig.info}</p>
      <div className="mt-4 text-center">
        {modalConfig.btnText && (
          <button
            className="h-[32px] w-[180px] rounded-md bg-primary text-black"
            onClick={() => setOpen(false)}
          >
            {modalConfig.btnText}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default TradeStatusModal;
